<template>
  <div>
    <div class="title-path">
      <a href="/">Home</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/users">Users</a>
    </div>
    <div class="mb-2 d-block d-lg-none d-md-none">
      <div class="btn btn-sm btn-block form-control btn-primary p-2" v-if="['Admin'].includes(this.user.role)">
        <i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;New User
      </div>
    </div>
    <div class="row list-actions">
      <div class="col left-column">
        <div class="list-tab" v-on:click="setParamsList('all')" v-bind:class="[userTab === 'all' ? 'active' : '']">
          All<span>{{userTotals.totalCount}}</span>
        </div>
        <div class="list-tab" v-on:click="setParamsList('manager')" v-bind:class="[userTab === 'manager' ? 'active' : '']">
          Managers<span>{{userTotals.totalManagers}}</span>
        </div>
        <div class="list-tab" v-on:click="setParamsList('agent')" v-bind:class="[userTab === 'agent' ? 'active' : '']">
          Agents<span>{{userTotals.totalAgents}}</span>
        </div>
      </div>
      <div class="col right-column d-none d-lg-block d-md-block">
        <div class="btn btn-sm btn-primary" v-on:click="newUser" v-if="['Admin'].includes(this.user.role)">
          <i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;New User
        </div>
      </div>
    </div>
    <SearchComponent
      @searchRecord="searchRecord($event)"
      :placeholder="'Search users by name or email'"
      :showSideOptions="false"
      :isRealTime="true"
    ></SearchComponent>
    <ListComponent
      :records="filteredUsers"
      :profile="'users'"
    ></ListComponent>
  </div>
</template>

<script>
  import SearchComponent from '../Search.vue'
  import ListComponent from '../List.vue'

  export default {
    components: {
      SearchComponent,
      ListComponent
    },
    data() {
      return {
        user: this.$userData.user,
        users: [],
        filteredUsers: [],
        userTab: 'all',
        userTotals: {
          totalCount: 0,
          totalManagers: 0,
          totalAgents: 0
        }
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      async getUsers(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/users?role=Manager,Agent`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.users = response.data.users
          this.filteredUsers = this.buildList(this.users)
          this.userTotals = {
            totalCount: this.filteredUsers.length,
            totalManagers: this.filteredUsers.filter(r => r.record.roles === "Manager").length,
            totalAgents: this.filteredUsers.filter(r => r.record.roles === "Agent").length
          }
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      searchRecord: function(search){
        this.filteredUsers = this.buildList(this.users.filter(record => (
          (record.name ? record.name.toLowerCase().match(new RegExp(search.toLowerCase())) : false) ||
          (record.email ? record.email.toLowerCase().match(new RegExp(search.toLowerCase())) : false)
        )))
      },
      setParamsList: function(val){
        this.$router.replace({ path: this.$route.path, query: { level: val } })
        this.userTab = val;
        switch(val){
          case 'manager':
            this.filteredUsers = this.buildList(this.users.filter(r => r.roles === 'Manager'))
            break
          case 'agent':
            this.filteredUsers = this.buildList(this.users.filter(r => r.roles === 'Agent'))
            break
          default:
            this.filteredUsers = this.buildList(this.users)
        }
      },
      validateAvatar: function (avatar){
        return avatar ? this.$getAvatar(avatar) : this.$getAvatar('/images/avatars/avatarArtboard-1.svg')
      },
      buildList: function(records){
        return records.map((record) => {
          return {
            profile: `users`,
            key: record._id,
            avatar: this.validateAvatar(record.avatar),
            header: record.name,
            subheader: record.email,
            tags: record.bunit.map(r => {
              return { text: r.name, background: 'primary-default' }
            }),
            indicators: record.roles === "Manager" && record.isAgent ?
              [
                {
                  key: `${record._id}_1`,
                  text: "Manager",
                  background: "success"
                },
                {
                  key: `${record._id}_2`,
                  text: "Agent",
                  background: "warning"
                },
                {
                  key: `${record._id}_2`,
                  text: record.isActive ? "Active" : "Inactive",
                  background: record.isActive ? "primary-default" : "primary-dark"
                }
              ] :
              [
                {
                  key: `${record._id}_1`,
                  text: record.roles,
                  background: record.roles === "Manager" ? "success" : "warning"
                },
                {
                  key: `${record._id}_2`,
                  text: record.isActive ? "Active" : "Inactive",
                  background: record.isActive ? "primary-default" : "primary-dark"
                }
              ],
            timestamp: {
              createdAt: record.createdAt,
              updatedAt: record.updatedAt
            },
            record: record
          }
        })
      },
      newUser: function(){
        this.$router.push({path: "/users/new", props: true, params: {isInternal: true }})
      },
      validateUser: async function(){
        if(!['Admin', 'Manager','Agent'].includes(this.user.role)){
          this.$alertify({
            group: 'notification',
            title: `Access denied`,
            type: 'warning',
            text: `You don't have access to this module.`
          })
          await this.$sleep(500);
          window.history.back()
        }
      }
    },
    mounted: function() {
      this.validateUser()
      this.userTab = this.$route.query.level
      if(!['all','manager','agent'].includes(this.userTab))
        this.userTab = 'all'
      this.getUsers();
    }
  }
</script>
